#Experience {
    /* Add any additional styles for the section here */
  }
  
  .Experience {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 1.5rem; */
    padding: 2.4rem 3.5rem;
  }

  
  .company{
    color: var(--color-primary)
  }

  .Experience >div {
    background: var(--color-bg-variant);
    padding: 2.4rem 2.5rem;
    border-radius: 2rem;
    transition: var(--transition);
    border: 1px solid transparent;
}

  .duration{
    color: var(--color-primary)
  }
  .exp_item {
    margin-bottom: 20px;
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    margin-bottom: 20px;
    background: var(--color-bg-variant);
    margin-right: 10px;
    
  }
  
  .exp_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
  }
  
  .company-duration {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .company-duration h2 {
    margin-bottom: 0;
    color: var(--color-primary);
  }
  .company-duration h2:nth-child(1) {
    font-size: 20px;
    /* margin-left: 10px; */
  }
  
  .company-duration h2:nth-child(2) {
    font-size: 14px;
    margin-left: 10px;
  }
  
  .role {
    font-weight: bold;
    color: skyblue;
  
  }
  
  .exp_item h3:nth-child(3) {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--color-primary);
  }
  
  .exp_desc {
    margin-top: 5px;
    font-size: 14px;
    text-align: justify;
  }
  
  /* @media (min-width: 1200px) {
    .exp_item {
      width: 95%;
    }
    .Experience{
      grid-template-columns: repeat(2, 1fr);
    }
  } */
  @media screen and (max-width:1200px) {
    .Experience{
      grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:900px) {
  .Experience{
    grid-template-columns: repeat(1, 1fr);
  }
} 