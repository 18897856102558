.project__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* repeat(3,1fr); */
    gap:2.5rem
}
.project__title{
    color: skyblue;
}

.project__item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.project__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.project__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
    /* height: 18rem; */

}

.project__item h3{
    margin: 1.2rem 0 2rem;
}

.project__item-cta{
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
}

.project_desc{
    /* height: 18rem; */
    text-align: justify;
}

.project__button{
    text-align: center;
    width: -webkit-fill-available;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.project__button:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.project__button-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

@media screen and (max-width:1024px) {
    .project__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width:600px) {
    .project__container{
        grid-template-columns: 1fr;
        gap: 1rem;
        /* height: max-content; */
        
    }
    
}